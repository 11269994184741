import { commonStyles, Text, View } from '../components/Themed';
import { RootTabScreenProps } from "../types";
import RadioButtonList from "../components/RadioButtonList";
import NextButton from "../components/NextButton";
import SignallerDataManager from "../services/SignallerDataManager";
import {MESSAGES} from "../constants/Messages";

export default function ChooseAuthorScreen({navigation}: RootTabScreenProps<'ChooseAuthor'>) {
    const options = [
        {
            title: MESSAGES.chooseAuthor.affected,
            text: MESSAGES.chooseAuthor.affectedDescription,
            value: MESSAGES.chooseAuthor.affected
        },
        {
            title: MESSAGES.chooseAuthor.helping,
            text: MESSAGES.chooseAuthor.helpingDescription,
            value: MESSAGES.chooseAuthor.helping
        },
    ];

    function selected(value: any)
    {
        SignallerDataManager.getInstance()?.setAuthorType(value);
    }

    selected(options[0].value);

    return (
        <View style={[commonStyles.container, commonStyles.content]}>
            <Text style={commonStyles.title}>{ MESSAGES.chooseAuthor.title }</Text>

            <RadioButtonList options={options} onCheck={selected}/>

            <NextButton onPress={() => {
                navigation.navigate('FormType')
            }} />
        </View>
    );
}