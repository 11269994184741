import { commonStyles, Text, View } from '../components/Themed';
import { RootTabScreenProps } from "../types";
import NextButton from "../components/NextButton";
import SignallerDataManager from "../services/SignallerDataManager";
import React, {useState} from "react";
import TextInputDynamicList from "../components/TextInputDynamicList";
import { MESSAGES } from "../constants/Messages";
import { Picker } from "@react-native-picker/picker";
import { StyleSheet } from "react-native";

export default function WitnessesPersonsScreen({navigation}: RootTabScreenProps<'WitnessesPersons'>) {
    let witnesses: any;
    let evidences: any;

    const reasons = [
        'interes publiczny',
        'społeczny',
        'pracowników',
        'pracodawcy',
        'lub inny interes',
    ];

    const [selectedReason, setSelectedValue] = useState(0);

    const witnessesChanged = (data: any) => {
        witnesses = data;
    }

    const evidencesChanged = (data: any) => {
        evidences = data;
    }

    const submit = () => {
        SignallerDataManager.getInstance()?.setWitnessesPersons(witnesses);
        SignallerDataManager.getInstance()?.setEvidencesPersons(evidences);
        SignallerDataManager.getInstance()?.setReason(selectedReason);
        navigation.navigate('Confirm');
    };

    return (
        <View style={[commonStyles.container, commonStyles.content]}>
            <Text style={commonStyles.title}>{ MESSAGES.witnessesPersons.title }</Text>
            <Text style={commonStyles.text}>{ MESSAGES.witnessesPersons.text }</Text>

            <TextInputDynamicList onChange={witnessesChanged} placeholder={ MESSAGES.common.inputYourDowod } title={ MESSAGES.witnessesPersons.witnesses } addLabel={ MESSAGES.witnessesPersons.addWitness } />
            <TextInputDynamicList onChange={evidencesChanged} placeholder={ MESSAGES.common.inputYourNameLastname } title={ MESSAGES.witnessesPersons.evidences } addLabel={ MESSAGES.witnessesPersons.addEvidence } />


            <Text style={{fontWeight: 'bold', textAlign: 'left', marginTop: 10, marginBottom: 10}}>Interes w zgłoszeniu ma charakter:</Text>

            <Picker
                selectedValue={selectedReason}
                style={styles.picker}
                onValueChange={(itemValue) => setSelectedValue(itemValue)}
            >
                { reasons.map((reason, index) => (
                    <Picker.Item key={index} label={reason} value={index} />
                )) }
            </Picker>

            <NextButton onPress={submit} />
        </View>
    );
}

const styles = StyleSheet.create({
    picker: {
        height: 50,
        borderRadius: 6,
        borderColor: '#D1D5DB',
        borderWidth: 1,
        width: '100%',
        paddingLeft: 10
    }
});