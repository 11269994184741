import { commonStyles, Text, View } from '../components/Themed';
import React from "react";
import NextButton from "../components/NextButton";
import { Image } from "react-native";
import { MESSAGES } from "../constants/Messages";
import Bridge from "../services/Bridge";

export default function SuccessScreen() {

    const exit = () => {
        Bridge.exit();
    };

    return (
        <View style={ commonStyles.container }>
            <Image style={ commonStyles.topImage } source={ require('../assets/images/introduce.svg') }/>
            <View style={ commonStyles.content }>
                <Text style={ commonStyles.title }>
                    { MESSAGES.success.title }
                </Text>
                <Text style={ commonStyles.text }>
                    { MESSAGES.success.text }
                </Text>
            </View>
            <NextButton onPress={ exit } title={ MESSAGES.success.complete }/>
        </View>
    );
}

