import { commonStyles, myListStyles, Text, View } from '../components/Themed';
import { RootTabScreenProps } from "../types";
import { ActivityIndicator, TouchableOpacity } from 'react-native';

import React, { useEffect, useState } from "react";
import { Ionicons } from '@expo/vector-icons';
import SignallerDataManager from '../services/SignallerDataManager';
import Globals from '../constants/Globals';
import { MESSAGES } from '../constants/Messages';


export default function MyListContentScreen({route, navigation}: RootTabScreenProps<'MyListContent'>) {
    const [displayState, setDisplayState] = useState(0);
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const {id} = route.params;
    
    const reasons = [
        'interes publiczny',
        'społeczny',
        'pracowników',
        'pracodawcy',
        'lub inny interes',
    ];

    const token = SignallerDataManager.getInstance()?.getToken();

    const getListSignalist = async () => {
        try {
            const response = await fetch(Globals.API_URL+'/v2.5/signaller/'+id+'/view');
            const json = await response.json();
            setData(json);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    function getDate (date:any) {
        return new Date(date*1000).toLocaleDateString("pl-PL");
    }

    const getReason = (item:any) => {
        return reasons[item];
    }

    const getStringArray = (data:any) => {
        if(data.length == 0) return MESSAGES.mylistContent.nodata;
        let i = 0;
        let row = '';
        let separator = ',';
        data.forEach((element:any) => {
            let _separator = i==0 ? '' : separator+' ';
            row += _separator+''+element.name;
            i++;
        });
        return row
    }

    const getRequestType = (request:any) => {
        if(request == 1) return MESSAGES.mylistContent.public;
        else return MESSAGES.mylistContent.private;
    }

    useEffect(() => {
        getListSignalist();
      }, []);

    return (
        <View style={[commonStyles.container, commonStyles.content, {justifyContent:'center'}]}>
            {isLoading ? <ActivityIndicator/> : data && token ? (
                <View style={[commonStyles.container, myListStyles.listContent]}>
                    <View style={[myListStyles.listContent, { borderBottomWidth: 0 }]}>
                        <Text style={[myListStyles.listSubTitle]}>{MESSAGES.mylistContent.title}</Text>
                    </View>
                    <View style={[myListStyles.listContent, myListStyles.listColumns]}>
                            <Text>
                                {MESSAGES.mylistContent.num}
                            </Text>
                            <Text style={[myListStyles.colorNeutralDark2]}>
                               {data.title}
                            </Text>
                    </View>
                    <View style={[myListStyles.listContent, myListStyles.listColumns]}>
                            <Text>
                                {MESSAGES.mylistContent.sent}
                            </Text>
                            <Text style={[myListStyles.colorNeutralDark2]}>
                                {getDate(data.created_at)}
                            </Text>
                    </View>
                    <View style={[myListStyles.listContent, myListStyles.listColumns]}> 
                            <Text>
                                {MESSAGES.mylistContent.inderest}
                            </Text>
                            <Text style={[myListStyles.colorNeutralDark2]}>
                                {getReason(data.reason)}
                            </Text>
                    </View>
                    <View style={[myListStyles.listContent, myListStyles.listColumns]}>
                            <Text>
                                {MESSAGES.mylistContent.forma}
                            </Text>
                            <Text style={[myListStyles.colorNeutralDark2]}>
                                {getRequestType(data.request_type)}
                            </Text>
                    </View>
                    <View style={[myListStyles.listContent, {display: (data.fullname != null) ? 'flex' : 'none'}]}>
                                <TouchableOpacity onPress={() => setDisplayState(displayState ? 0 : 1)}>
                                    <View style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', marginRight:10}}>
                                        <Text style={[myListStyles.listSubTitle, myListStyles.colorNeutralDark]}>
                                            {MESSAGES.mylistContent.infoAboutSender}
                                        </Text>
                                        <Ionicons style={[commonStyles.iconPosition,{display: displayState ? 'none' : 'flex'}]} name="chevron-down-outline" ios="chevron-down-outline" android="chevron-down-outline" size={20} color="black" />
                                        <Ionicons style={[commonStyles.iconPosition,{display: displayState ? 'flex' : 'none'}]} name="chevron-up-outline" ios="chevron-up-outline" android="chevron-up-outline" size={20} color="black" />
                                    </View>
                                </TouchableOpacity>
                                
                                    <View style={{display: displayState ? 'flex' : 'none'}}>
                                        <Text style={[myListStyles.listContentText, myListStyles.listDate, myListStyles.colorNeutralDark2,{marginTop:5}]}>
                                            {data.fullname? data.fullname : MESSAGES.mylistContent.nodata}
                                        </Text>
                                        <Text style={[myListStyles.listContentText, myListStyles.listDate, myListStyles.colorNeutralDark2]}>
                                            {data.title_work? data.title_work : MESSAGES.mylistContent.nodata} {data.position? data.position : ''}
                                        </Text>
                                        <Text style={[myListStyles.listContentText, myListStyles.listDate, myListStyles.colorNeutralDark2]}>
                                            {data.phone? data.phone : MESSAGES.mylistContent.nodata}
                                        </Text>
                                        <Text style={[myListStyles.listContentText, myListStyles.listDate, myListStyles.colorNeutralDark2]}>
                                            {data.email? data.email : MESSAGES.mylistContent.nodata}
                                        </Text>
                                    </View>
                    </View>
                    <View style={[myListStyles.listContent]}>
                            <View>
                                <Text style={[myListStyles.listSubTitle, myListStyles.colorNeutralDark]}>
                                    {MESSAGES.mylistContent.text}
                                </Text>
                            </View>
                            <View>
                                <Text style={[myListStyles.listContentText]}>
                                    {data.info}    
                                </Text>
                            </View>
                        </View>
                        <View style={[myListStyles.listContent]}>
                            <View>
                                <Text style={[myListStyles.listSubTitle, myListStyles.colorNeutralDark]}>
                                    {MESSAGES.mylistContent.affected}
                                </Text>
                            </View>
                            <View>
                                <Text style={[myListStyles.listContentText]} >
                                    {getStringArray(data['affected'])}
                                </Text>
                            </View>
                        </View>
                        <View style={[myListStyles.listContent]}>
                            <View>
                                <Text style={[myListStyles.listSubTitle, myListStyles.colorNeutralDark]}>
                                    {MESSAGES.mylistContent.responsible}
                                </Text>
                            </View>
                            <View>
                                <Text style={[myListStyles.listContentText]}>
                                    {getStringArray(data['responsible'])}
                                </Text>
                            </View>
                        </View>
                        <View style={[myListStyles.listContent]}>
                            <View>
                                <Text style={[myListStyles.listSubTitle, myListStyles.colorNeutralDark]}>
                                    {MESSAGES.mylistContent.witness}
                                </Text>
                            </View>
                            <View>
                                <Text style={[myListStyles.listContentText]}>
                                    {getStringArray(data['witness'])}
                                </Text>
                            </View>
                        </View>
                        <View style={[myListStyles.listContent]}>
                            <View>
                                <Text style={[myListStyles.listSubTitle, myListStyles.colorNeutralDark]}>
                                    {MESSAGES.mylistContent.evidence}
                                </Text>
                            </View>
                            <View>
                                <Text style={[myListStyles.listContentText]}>
                                    {getStringArray(data['evidence'])}
                                </Text>
                            </View>
                        </View>
                    </View>
        ):(
            <View>
                <Text>
                    {MESSAGES.mylistContent.nodata}
                </Text>
            </View>
        )}
    </View>
    );
}