import React, { useRef, useState } from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import { TextInput } from "./TextInput";
import {MESSAGES} from "../constants/Messages";

export default function TextInputDynamicList(props: { title: string; addLabel: string; onChange: any; placeholder:string }) {
    const { title, addLabel, onChange, placeholder } = props;

    const [nameValue, setNameValue] = useState('');
    const [numInputs, setNumInputs] = useState(0);
    const refInputs = useRef<string[]>([]);

    const addField = () => {
        refInputs.current.push('');
        setNumInputs(value => value + 1);
    }
    
    const setInputValue = (index: number, value: string) => {
        const inputs = refInputs.current;
        inputs[index] = value;

        setNameValue(value);

        onChange(inputs.filter((value) => {
            return value && value.length > 0;
        }));
    }

    return (
        <View style={{ width: '100%', marginTop: 20 }}>
            <Text style={ styles.dynamicTextTitle }>{ title }</Text>

            { refInputs.current.map((value, i) => {
                return <TextInput key={ i }
                    onChangeText={ value => setInputValue(i, value) }
                    value={refInputs.current[i]}
                    placeholder={ placeholder }
                />
            }) }

            <Pressable style={{ marginTop: 10 }} onPress={addField}>
                <Text style={ styles.dynamicAddButton }>+ { addLabel }</Text>
            </Pressable>
        </View>
    );
}

const styles = StyleSheet.create({
    dynamicTextTitle: {
        fontWeight: '500',
        marginBottom: 10,
    },
    dynamicAddButton: {
        color: '#0379F7',
    }
});