
export default class Bridge {
    public static init()
    {
        const userAgent = window.navigator.userAgent.toLowerCase();
        const ios = /iphone|ipod|ipad/.test(userAgent);

        function setupWKWebViewJavascriptBridge(callback: any) {
            // @ts-ignore
            if (window.WKWebViewJavascriptBridge) {
                // @ts-ignore
                return callback(WKWebViewJavascriptBridge);
            }

            // @ts-ignore
            if (window.WKWVJBCallbacks) {
                // @ts-ignore
                return window.WKWVJBCallbacks.push(callback);
            }

            // @ts-ignore
            window.WKWVJBCallbacks = [callback];
            // @ts-ignore
            window.webkit.messageHandlers.iOS_Native_InjectJavascript.postMessage(null)
        }

        if (ios) {
            setupWKWebViewJavascriptBridge(function (bridge2: any) {
                // @ts-ignore
                window.bridge2 = bridge2
            })
        }
    }
    public static exit()
    {
        if ('bridge2' in window) {
            // @ts-ignore
            bridge2.callHandler('PhBCommand', {'command': 'exit'});
        } else if ('Android' in window) {
            // @ts-ignore
            Android.finishActivity();
        }
    }
}