import { Text, TextProps } from './Themed';
import {StyleSheet} from "react-native";

export function ErrorText(props: TextProps) {
  const style = StyleSheet.create({
    errorText: {
      color: 'red',
      fontSize: 12,
      marginBottom: 6
    }
  });

  return <Text {...props} style={[props.style, style.errorText]} />;
}
