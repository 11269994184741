import {commonStyles, myListStyles, Text, View} from '../components/Themed';
import {RootTabScreenProps} from "../types";
import NextButton from "../components/NextButton";
import SignallerDataManager from "../services/SignallerDataManager";
import {Controller, useForm} from "react-hook-form";
import {TextInput} from "../components/TextInput";
import {ErrorText} from "../components/ErrorText";
import React, { useState } from "react";
import {Button, FlatList, Image, LayoutAnimation, SafeAreaView, SectionList, StyleSheet, TouchableHighlight, TouchableOpacity} from "react-native";
import {MESSAGES} from "../constants/Messages";
import Colors from '../constants/Colors';
import { Card, List } from 'react-native-paper';
import { disableExpoCliLogging } from 'expo/build/logs/Logs';

export default function ViolationDetailsScreen({navigation}: RootTabScreenProps<'ViolationDetails'>) {
    
    const {control, handleSubmit, formState: {errors}} = useForm({
        defaultValues: {
            violationDescription: '',
        }
    });
    
    let [filesUploader, setFilesUploader] = useState([]);
    
    const removeFile = (index: any) => {
        const dt = new DataTransfer();
        for (let file of filesUploader) {
            dt.items.add(file);
        }
        dt.items.remove(index);
        document.getElementById('filesAttach').files = dt.files;
        setFilesUploader(dt.files);
    };

    const onSubmit = (data: any) => {
        SignallerDataManager.getInstance()?.setDescription(data.violationDescription);
        navigation.navigate('AffectedPersons');
    };

    // Create a reference to the hidden file input element
    const hiddenFileInput = React.useRef(null);

    const handleClick = (event: any) => {
        document.getElementById('filesAttach').click();
        //hiddenFileInput.current.click(); 
    };

    const handleChange = (event: any) => {
        const filesUploaded = event.target.files;
        setFilesUploader(filesUploaded);
        // props.handleFile(fileUploaded);
    };


    return (
        <View style={[commonStyles.container, commonStyles.content]}>
            <Text style={commonStyles.title}>{ MESSAGES.violationDetails.title }</Text>
            <Text style={commonStyles.text}>{ MESSAGES.violationDetails.text }</Text>

            <Controller
                control={control}
                rules={{
                    required: true,
                    maxLength: 64,
                }}
                render={({field: {onChange, onBlur, value}}) => (
                    <TextInput
                        placeholder={ MESSAGES.violationDetails.detailsPlaceholder }
                        style={{height: 'calc(100% - 370px)', marginTop: 20, borderWidth:0, borderRadius:0, borderTopWidth: 1, borderColor: Colors.lightGray, paddingRight: 0, paddingLeft:0, paddingTop: 20, outline:'none'}}
                        multiline={true}
                        onBlur={onBlur}
                        onChangeText={onChange}
                        value={value}
                    />
                )}
                name="violationDescription"
            />

            {errors.violationDescription && <ErrorText>{MESSAGES.common.thisIsRequired}</ErrorText>}

            <View style={[commonStyles.container,{display: 'flex', borderTopColor:'#F3F4F6', borderTopWidth:1, borderBottomColor:'#F3F4F6', borderBottomWidth:1, flex:'none'}]}>
                <FlatList
                    style={{overflow:'visible'}}
                    horizontal
                    keyExtractor={(item, index) => 'key'+index} 
                    data={filesUploader}
                    renderItem = {item => (
                        <View style={{display: 'flex', position: 'relative', alignItems:'center', backgroundColor:'#F3F4F6', borderRadius:6, padding:6, margin:6, width:100}}>
                            <Image style={{width:20,height:20,marginBottom:2}} source={require('../assets/images/file_gray.svg')}/>
                            <Text style={[commonStyles.defaultFont, myListStyles.colorNeutralDark2, {fontSize:11, marginBottom:2, overflowWrap: 'break-word', wordBreak: 'break-all', wordWrap: 'break-word'}]}>{item.item.name}</Text>
                            <Text style={[commonStyles.defaultFont, myListStyles.colorNeutralDark2, {fontSize:12}]}>{item.item.type.split("/")[1]}</Text>
                            <TouchableOpacity style={{position: 'absolute', top: '-10px', zIndex:999, right: '-10px' }} onPress={() => {removeFile(item.index)}}>
                                <View style={{width: 24, height: 24, display:'flex', alignItems:'center', justifyContent:'center', borderRadius: 24, backgroundColor: '#ffffff', borderColor:'#D1D5DB', borderWidth:1}}>
                                    <Image style={{width: 10, height: 10}} source={require('../assets/images/delete_icon.svg')}/>
                                </View>
                            </TouchableOpacity>
                        </View>
                    )} 
                />
            </View>

            <TouchableOpacity style={{ width: 'calc(100% - 40px)', position: 'absolute', bottom: '75px', zIndex:999, left: '20px', right: '20px' }} onPress={handleClick}>
                <View style={styles.uploadButton}>
                    <Image style={styles.uploadImg} source={require('../assets/images/file.svg')}/>
                    <Text style={styles.uploadText}>{ MESSAGES.violationDetails.attachPhoto }</Text>
                </View>
            </TouchableOpacity>

            <input
                type="file"
                id="filesAttach"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{position:'absolute',left:-99999}}
                multiple
            />

            <NextButton onPress={handleSubmit(onSubmit)}/>
        </View>
    );
}

const styles = StyleSheet.create({
    uploadButton: {
        width: '100%',
        height: 48,
        backgroundColor: '#E6F2FF',
        borderRadius: 6,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    uploadImg: {
        width: 17,
        height: 20,
        marginRight: 5,
    },
    uploadText: {
        color: '#0379F7',
        fontWeight: '500',
    }
});