import GLOBALS from '../constants/Globals';
import { commonStyles, Text, View } from '../components/Themed';
import { RootTabScreenProps } from "../types";
import React, {useState} from "react";
import NextButton from "../components/NextButton";
import { ActivityIndicator, StyleSheet } from "react-native";
import { Checkbox } from "react-native-paper";
import SignallerDataManager from "../services/SignallerDataManager";
import axios from "axios";
import {MESSAGES} from "../constants/Messages";
import { ErrorText } from '../components/ErrorText';

export default function ConfirmScreen({navigation}: RootTabScreenProps<'Confirm'>) {
    const [confirmCheckBox, setConfirmCheckBox] = useState(false);
    const [confirmCheckBox2, setConfirmCheckBox2] = useState(false);
    const [confirmCheckBox3, setConfirmCheckBox3] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const onSubmit = () => {
        try {
            setLoading(true);
            const urlParams = new URLSearchParams(window.location.search);
            const token = urlParams.get('token');

            if (token)
                SignallerDataManager.getInstance()?.setToken(token);

            const config = { headers: { 'Content-Type': 'multipart/form-data;charset=utf-8; boundary=----WebKitFormBoundaryyrV7KO0BoCBuDbTL' } };
            const fulldata = JSON.stringify(SignallerDataManager.getInstance()?.getData());
            
            const formData = new FormData()

            const myFileInput = document.getElementById('filesAttach');

            if (myFileInput) {
                const attachedFiles = myFileInput.files;
                for (let i = 0, numFiles = attachedFiles.length; i < numFiles; i++) {
                    let file = attachedFiles[i];
                    formData.append('files[]', file, file.name);
                  }
            };

            if(fulldata){
                formData.append('data', fulldata); 
            }
            
            axios.post(GLOBALS.API_URL+'/v2.5/signaller/send', formData, config)
            .then(() => {
                navigation.navigate('Success');
            });

        } catch (e) {
            console.log(e);
        }
    };

    return (
        <View style={[commonStyles.container, commonStyles.content]}>
            {isLoading ? <ActivityIndicator style={{height:'100%'}}/> :
                <>
                    <View style={{ height: 'calc(100% - 50px)', overflow: 'scroll', paddingBottom: 80 }}>
                        <Text style={commonStyles.title}>Niniejszym powierzam z dniem następujące obowiązki związane z:</Text>
                        <Text style={styles.h1}>jak rozpoznajemy Twoje zgłoszenie wersja 1.1. z dnia 3 kwietnia 2022 r.</Text>
                        <Text style={styles.p}>Twoje zgłoszenie będzie rozpoznawane przez radców prawnych, z Kancelarii Prawo Gospodarka Zdrowie Banaszewska Modro sp.k., związanych tajemnicą radcowską w zakresie przekazanych przez Ciebie danych osobowych i informacji. </Text>
                        <View style={styles.list}>
                            <View style={styles.listHeader}>1 Nasze cele:</View>
                            <View style={styles.listSub}>
                                <View style={styles.listSubElement}>1.1 stworzenie kompleksowej procedury dotyczącej zgłaszania informacji o naruszeniach oraz ochrony osób dokonujących zgłoszeń;</View>
                                <View style={styles.listSubElement}>1.2 poprawa społecznego postrzegania działań osób zgłaszających;</View>
                                <View style={styles.listSubElement}>1.3 ochrona osób zgłaszających;</View>
                                <View style={styles.listSubElement}>1.4 ochrona Pracodawcy poprzez wczesne wykrycie i usunięcie zgłoszonych przypadków naruszeń;</View>
                                <View style={styles.listSubElement}>1.5 upowszechnianie postawy obywatelskiej odpowiedzialności;</View>
                                <View style={styles.listSubElement}>1.6 określenie zasad podejmowania u Pracodawcy działań następczych, a więc stanowiących reakcję na zgłoszenie o naruszeniu.</View>
                            </View>
                        </View>
                        <View style={styles.list}>
                            <View style={styles.listHeader}>2 Definicje:</View>
                            <View style={styles.listSub}>
                                <View style={styles.listSubElement}>2.1 działanie następcze –działanie podjęte przez Pracodawcę w celu oceny prawdziwości zarzutów zawartych w zgłoszeniu oraz, w stosownych przypadkach, w celu przeciwdziałania naruszeniu prawa będącemu przedmiotem zgłoszenia, w tym przez dochodzenie wewnętrzne, postępowanie wyjaśniające, wniesienie oskarżenia, działanie podjęte w celu odzyskania środków finansowych lub zamknięcie procedury przyjmowania i weryfikacji zgłoszeń;</View>
                                <View style={styles.listSubElement}>2.2 działanie odwetowe –bezpośrednie lub pośrednie działanie lub zaniechanie, które jest spowodowane zgłoszeniem które narusza lub może naruszyć prawa Zgłaszającego lub wyrządza lub może wyrządzić szkodę Zgłaszającemu;</View>
                                <View style={styles.listSubElement}>2.3 informacji o naruszeniu prawa – informacja , w tym uzasadnione podejrzenie, dotyczące zaistniałego lub potencjalnego naruszenia prawa, do które go doszło lub prawdopodobnie dojdzie w organizacji, w której zgłaszający pracuje lub pracował, lub w innej organizacji, z którą zgłaszający utrzymuje lub utrzymywał kontakt w kontekście związanym z pracą, lub dotyczącą próby ukrycia takiego naruszenia prawa;</View>
                                <View style={styles.listSubElement}>2.4 informacja zwrotna – przekazanie Zgłaszającemu informacji na temat planowanych lub podjętych działań następczych i powodów takich działań;</View>
                                <View style={styles.listSubElement}>2.5 kontekście związanym z pracą – całokształt okoliczności związanych ze stosunkiem pracy lub innym stosunkiem prawnym stanowiącym podstawę świadczenia pracy, w ramach których uzyskano informację o naruszeniu prawa;</View>
                                <View style={styles.listSubElement}>2.6 mobbing –działania lub zachowania dotyczące pracownika lub skierowane przeciwko pracownikowi, polegające na uporczywym i długotrwałym nękaniu lub zastraszaniu pracownika, wywołujące u niego zaniżoną ocenę przydatności zawodowej, powodujące lub mające na celu poniżenie lub ośmieszenie pracownika, izolowanie go lub wyeliminowanie z zespołu współpracowników;</View>
                                <View style={styles.listSubElement}>2.7 organ centralny – organ administracji publicznej właściwy w sprawach udzielania informacji i wsparcia w sprawach zgłaszania naruszeń prawa i publicznego ujawniania naruszeń prawa oraz przyjmowania zgłoszeń zewnętrznych o naruszeniach prawa w dziedzinach objętych ustawą, ich wstępnej weryfikacji i przekazania organom właściwym celem podjęcia działań następczych;</View>
                                <View style={styles.listSubElement}>2.8 organ publiczny –organ administracji publicznej, który ustanowił procedurę przyjmowania zgłoszeń zewnętrznych o naruszeniach prawa w dziedzinie należącej do zakresu działania tego organu;</View>
                                <View style={styles.listSubElement}>2.9 osoba, której dotyczy zgłoszenie – osoba fizyczna, osoba prawna lub jednostka organizacyjna nieposiadająca osobowości prawnej, której ustawa przyznaje zdolność prawną, wskazaną w zgłoszeniu jako osoba, która dopuściła się naruszenia prawa lub z którą osoba ta jest powiązana;</View>
                                <View style={styles.listSubElement}>2.10 osoba pomagająca w dokonani zgłoszenia – osoba fizyczna, która pomaga Zgłaszającemu w zgłoszeniu w kontekście związanym z pracą;</View>
                                <View style={styles.listSubElement}>2.11 osoba powiązana ze zgłaszającym –osoba fizyczna, która może doświadczyć działań odwetowych, w tym współpracownika lub członka rodziny Zgłaszającego;</View>
                                <View style={styles.listSubElement}>2.12 pracownik – pracownik w rozumieniu art. 2 ustawy z dnia 26 czerwca 1974 r. – Kodeks pracy oraz pracownika tymczasowego w rozumieniu art. 2 pkt 2 ustawy z dnia z dnia 9 lipca 2003 r. o zatrudnianiu pracowników tymczasowych (Dz. U. z 2019 r. poz. 1563 z późn. zm.);</View>
                                <View style={styles.listSubElement}>2.13 rejestr zgłoszeń wewnętrznych –rejestr prowadzony przez Pracodawcę, dokumentujący zgłoszenia wewnętrzne u Pracodawcy w Zakładzie pracy;</View>
                                <View style={styles.listSubElement}>2.14 RODO - rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (Dz. Urz. UE L 119 z 04.05.2016, str. 1, z późn. zm.);</View>
                                <View style={styles.listSubElement}>2.15 zgłoszeniu wewnętrznym –przekazanie informacji o naruszeniu  Pracodawcy;</View>
                                <View style={styles.listSubElement}>2.16 zgłoszeniu zewnętrznym –przekazanie informacji o naruszeniu  do organu centralnego lub organu publicznego.</View>
                            </View>
                        </View>
                        <View style={styles.list}>
                            <View style={styles.listHeader}>3 Rejestr zgłoszeń wewnętrznych: </View>
                            <View style={styles.listSub}>
                                <View style={styles.listSubElement}>3.1 prowadzimy Rejestr zgłoszeń wewnętrznych w imieniu i na rzecz Pracodawcy;</View>
                                <View style={styles.listSubElement}>3.2 administrujemy danymi osobowymi zgromadzonych w tym Rejestrze, na rzecz w imieniu i na rzecz Pracodawcy;</View>
                                <View style={styles.listSubElement}>3.3 wpisu do Rejestru zgłoszeń wewnętrznych dokonujemy się podstawie zgłoszenia wewnętrznego;</View>
                                <View style={styles.listSubElement}>3.4 w Rejestrze zgłoszeń wewnętrznych gromadzimy następujące dane: (i) numer sprawy, (ii) przedmiot naruszenia, (iii) dane osoby zgłaszającej, chyba, że zgłoszenie jest anonimowe; (iv) datę dokonania zgłoszenia wewnętrznego,  (v) datę przyjęcia zgłoszenia; (vi) datę potwierdzenia przyjęcia zgłoszenia Zgłaszającemu, (vii) informacje o podjętych działaniach następczych,  (viii) datę zakończenia sprawy; (ix) datę przekazania informacji zwrotnej, (x) działania podjęte w ramach monitoringu;</View>
                                <View style={styles.listSubElement}>3.5 dane w Rejestrze zgłoszeń wewnętrznych są przechowane przez okres 5 lat od dnia przyjęcia zgłoszenia.</View>

                            </View>
                        </View>
                        <View style={styles.list}>
                            <View style={styles.listHeader}>4 Postępowanie:</View>
                            <View style={styles.listSub}>
                                <View style={styles.listSubElement}>4.1 zgłoszenia traktowane są z należytą powagą i starannością z zachowaniem poufności, a przy ich rozpatrywaniu obowiązuje  zasada bezstronności i obiektywizmu;</View>
                                <View style={styles.listSubElement}>4.2 decydujemy o działaniach następczych, w tym weryfikujemy zgłoszenie i dokonujemy oceny prawdziwości zarzutów zawartych w  tym zgłoszeniu;</View>
                                <View style={styles.listSubElement}>4.3 zgłoszenie anonimowe może być rozpoznane tylko wtedy, gdy przewiduje do Pracodawca;</View>
                                <View style={styles.listSubElement}>4.4 podczas rozpatrywania zgłoszeń wszystkie osoby biorące udział w postępowaniu są zobowiązane do dołożenia należytej staranności, aby uniknąć podjęcia decyzji na podstawie bezpodstawnych oskarżeń, niemających potwierdzenia w faktach i zebranych dowodach oraz z zachowaniem poszanowania godności i dobrego imienia pracowników i osób, których zgłoszenie dotyczy;</View>
                                <View style={styles.listSubElement}>4.5 możemy zwrócić się do Zgłaszającego (Sygnalisty) o wyjaśnienia lub dodatkowe informacje w zakresie przekazanych informacji, jakie mogą być w jego posiadaniu, wykorzystując adres do kontaktu;</View>
                                <View style={styles.listSubElement}>4.6 jeżeli Zgłaszający sprzeciwia się przesyłaniu żądanych wyjaśnień lub dodatkowych informacji lub przesłanie takich żądań może zagrozić ochronie tożsamości Zgłaszającego odstępujemy od żądania wyjaśnień lub dodatkowych informacji;</View>
                                <View style={styles.listSubElement}>4.7 w uzasadnionych przypadkach przekazujemy, bez zbędnej zwłoki, właściwym instytucjom, organom lub jednostkom organizacyjnym Unii Europejskiej informacje zawarte w zgłoszeniu w celu dalszego postępowania wyjaśniającego;</View>
                                <View style={styles.listSubElement}>4.8 działania następcze są prowadzone bez zbędnej zwłoki;</View>
                                <View style={styles.listSubElement}>4.9 informacja zwrotna jest przekazywana Zgłaszającemu w terminie nieprzekraczającym 3 miesięcy od potwierdzenia przyjęcia zgłoszenia lub, w przypadku nieprzekazania potwierdzenia Zgłaszającemu, w terminie 3 miesięcy od upływu 7 dni od dokonania zgłoszenia w wyniku przeprowadzonych  działań następczych zgłoszenie, może zostać uznane za zasadne i wówczas podejmowane są działania naprawcze lub zawiadamia się właściwy organ centralny lub właściwy organ publiczny;</View>
                                <View style={styles.listSubElement}>4.10 rekomendujemy Pracodawcy podjęcie działań naprawczych, a decyzje w tym zakresie podejmuje Pracodawca;</View>
                                <View style={styles.listSubElement}>4.11 w sytuacji, gdy w toku postępowania zostanie ustalone, że zarzuty zawarte w zgłoszeniu nie znajdują potwierdzenia stwierdzamy jego bezzasadność;</View>
                                <View style={styles.listSubElement}>4.12 w każdym wypadku o wyniku postępowania następczego informuje się osobę Zgłaszającą (Sygnalistę) przekazując mu informację zwrotną;</View>
                                <View style={styles.listSubElement}>4.13 nie podejmujemy działań następczych w przypadku, gdy w zgłoszeniu dotyczącym sprawy będącej już przedmiotem wcześniejszego zgłoszenia nie zawarto istotnych nowych informacji na temat naruszeń w porównaniu z wcześniejszym zgłoszeniem - nie dotyczy to informacji o wyniku postępowania.</View>
                            </View>
                        </View>
                        <View style={styles.list}>
                            <View style={styles.listHeader}>5 Działania naprawcze w przypadku naruszenia:</View>
                            <View style={styles.listSub}>
                                <View style={styles.listSubElement}>5.1 przypadku stwierdzenia naruszenia u Pracodawcy w Zakładzie pracy podejmuje się działania naprawcze uzależnione w szczególności od rodzaju i rozmiaru stwierdzonych naruszeń;</View>
                                <View style={styles.listSubElement}>5.2 celem działań naprawczych jest, przede wszystkim, doprowadzenie do sytuacji zgodnej z prawem oraz przeciwdziałanie możliwości powstania tożsamego lub podobnego naruszenia w przyszłości - w tym celu Pracodawca dokonuje, przy naszej pomocy, wszechstronnej oceny możliwych działań naprawczych, przy uwzględnieniu opinii i oceny osób lub organów lub komórek organizacyjnych w Zakładzie pracy;</View>
                                <View style={styles.listSubElement}>5.3 do działań naprawczych podejmowanych przez Pracodawcę w Zakładzie pracy zalicza się, przede wszystkim: (i) wydanie odpowiednich poleceń pracownikom, (ii) zlecenie przeprowadzenia dodatkowych czynności kontrolnych, (iii) podjęcie środków przewidzianych przez przepisy prawa pracy wobec pracowników, który dopuścili się naruszeń, nie wykluczając rozwiązania umowy o pracę bez wypowiedzenia; (iv) wdrożenie procedur i zasad postępowania, w tym w szczególności, procedur i zasad compliance;</View>
                                <View style={styles.listSubElement}>5.4 pracodawca może skonsultować podjęcie działań naprawczych w sposób tożsamy dla przyjęcia niniejszego Regulaminu.</View>
                            </View>
                        </View>
                        <View style={styles.list}>
                            <View style={styles.listHeader}>6 Nasz monitoring</View>
                            <View style={styles.listSub}>
                                <View style={styles.listSubElement}>6.1 monitorujemy Twoja  sytuację prawną w Zakładzie pracy oraz sytuację prawną osoby pomagającej w dokonaniu zgłoszenia w Zakładzie pracy, lub osoby powiązanej;</View>
                                <View style={styles.listSubElement}>6.2 monitorowanie obejmuje analizę uzasadnienia wszelkich wniosków Twoich przełożonych, oraz przełożonych osoby pomagającej w dokonaniu zgłoszenia lub osoby powiązanej, dotyczących zmiany sytuacji prawnej i faktycznej w ramach stosunku pracy lub innego stosunku prawnego na podstawie, którego wykonywana jest praca (np. rozwiązanie umowy o pracę, zmiana zakresu czynności, przeniesienie do innej komórki organizacyjnej/na inne stanowisko pracy, degradacja stanowiskowa, płacowa, podnoszenie kompetencji, dodatkowe wynagrodzenie przyznawane pracownikom – dodatki, nagrody, premie, zmiana warunków świadczenia pracy – wynagrodzenie, wymiar etatu, godziny pracy, udzielenie pracownikowi urlopu wypoczynkowego / szkoleniowego / bezpłatnego itp.);</View>
                                <View style={styles.listSubElement}>6.3 w przypadku stwierdzenia lub podejrzenia działań zmierzających do pogorszenia Twojej sytuacji prawnej lub faktycznej, osoby pomagającej w dokonaniu zgłoszenia, lub osoby powiązanej  poinformujemy o tym osobę wykonującą u Pracodawcy czynności z zakresu prawa pracy w imieniu Pracodawcy, celem zatrzymania tych działań.</View>
                            </View>
                        </View>
                        <Text style={styles.h1}>Twoje prawa i Twoja ochrona</Text>
                        <View style={styles.list}>
                            <View style={styles.listHeader}>1 czy podlegasz ochronie?</View>
                            <View style={styles.listSub}>
                                <View style={styles.listSubElement}>1.1 Podlegasz ochronie pod warunkiem, że masz uzasadnione podstawy sądzić, że będąca przedmiotem zgłoszenia informacja o naruszeniu prawa jest prawdziwa w momencie dokonywania zgłoszenia i, że informacja faktycznie stanowi informację o naruszeniu prawa.</View>
                                <View style={styles.listSubElement}>1.2 Tobie, osobie Ci pomagającej w dokonaniu zgłoszenia, oraz osobie z Tobą powiązanej Pracodawca zapewnia ochronę przed możliwymi działaniami odwetowymi, a także przed szykanami, dyskryminacją i innymi formami wykluczenia lub nękania.</View>
                                <View style={styles.listSubElement}>1.3 Pracodawca wspólnie z Nami zapewnia, aby wyłączenie lub ograniczenia prawa do zgłoszenia naruszenia nie następowało pod pozorem bardziej ogólnie sformułowanych postanowień, na przykład o obowiązku zachowania tajemnicy co do informacji, których ujawnienie mogłoby narazić Pracodawcę na szkodę, troski o dobro Zakładu pracy, lojalności lub podobnych.</View>
                                <View style={styles.listSubElement}>1.4 Pracodawca wspólnie z Nami zapewnia, aby w Zakładzie pracy nie dochodziło do możliwości powoływania się na zobowiązania prawne lub umowne osób fizycznych, takie jak klauzule lojalności w umowach lub umowy o zachowanie poufności, w celu uniemożliwienia dokonania zgłoszenia, w celu odmowy ochrony lub w celu ukarania osób dokonujących zgłoszenia za zgłoszenie informacji na temat naruszeń.</View>
                                <View style={styles.listSubElement}>1.5 Pracodawca wspólnie z Nami uznaje nieważność z mocy prawa tzw. klauzul kneblujących (ang. gagging clauses), tj. klauzul ograniczających możliwość zgłoszenia informacji o naruszeniu, które mogłyby być zamieszczane w umowach o pracę lub innych aktach, na których podstawie powstaje lub kształtowany jest stosunek pracy.</View>
                                <View style={styles.listSubElement}>1.6 Pracodawca wspólnie z Nami uznaje, iż postanowienia umów o pracę oraz innych aktów, w zakresie, w jakim bezpośrednio lub pośrednio wyłączają lub ograniczają prawo do dokonania zgłoszenia lub ujawnienia informacji o naruszeniu, będą z mocy prawa nieważne.</View>
                                <View style={styles.listSubElement}>1.7 w przypadku, gdy dokonujesz zgłoszenia na temat naruszeń zgodnie z obowiązującymi przepisami oraz zasadami obowiązującymi u Pracodawcy, a informacje te zawierają tajemnice przedsiębiorstwa, takie zgłoszenie uznaje się za zgodne z prawem z zastrzeżeniem spełnienia warunków art. 3 ust. 2 dyrektywy (UE) 2016/943. Zgodnie z art. 3 ust 2 dyrektywy (UE) 2016/943 „Pozyskiwanie, wykorzystywanie lub ujawnianie tajemnicy przedsiębiorstwa uznaje się za zgodne z prawem w zakresie, w jakim takiego pozyskiwania, wykorzystywania lub ujawniania wymaga prawo unijne lub krajowe lub w jakim na nie zezwala.”</View>
                            </View>
                        </View>
                        <View style={styles.list}>
                            <View style={styles.listHeader}>2 działania odwetowe:</View>
                            <View style={styles.p}>U Pracodawcy w Zakładzie pracy obowiązuje zakaz wszelkich działań odwetowych wobec Zgłaszających (Sygnalistów), w tym pracowników, do których zalicza się: </View>
                            <View style={styles.listSub}>
                                <View style={styles.listSubElement}>2.1 zawieszenie</View>
                                <View style={styles.listSubElement}>2.2	przymusowy urlop bezpłatny;</View>
                                <View style={styles.listSubElement}>2.3	zwolnienie lub równoważne środki;</View>
                                <View style={styles.listSubElement}>2.4	degradację lub wstrzymanie awansu;</View>
                                <View style={styles.listSubElement}>2.5	przekazanie obowiązków;</View>
                                <View style={styles.listSubElement}>2.6	zmianę miejsca pracy;</View>
                                <View style={styles.listSubElement}>2.7	obniżenie wynagrodzenia;</View>
                                <View style={styles.listSubElement}>2.8	zmianę godzin pracy;</View>
                                <View style={styles.listSubElement}>2.9	wstrzymanie szkoleń;</View>
                                <View style={styles.listSubElement}>2.10 negatywną ocenę wyników lub negatywną opinię o pracy;</View>
                                <View style={styles.listSubElement}>2.11 nałożenie lub zastosowanie jakiegokolwiek środka dyscyplinarnego;</View>
                                <View style={styles.listSubElement}>2.12 nagany lub inne kary, w tym finansowej;</View>
                                <View style={styles.listSubElement}>2.13 przymus, zastraszanie, mobbing lub wykluczenie, dyskryminację, niekorzystne lub niesprawiedliwe traktowanie;</View>
                                <View style={styles.listSubElement}>2.14 nieprzekształcenie umowy o pracę na czas określony w umowę o pracę na czas nieokreślony, w sytuacji gdy pracownik mógł mieć uzasadnione oczekiwania, że zostanie mu zaoferowane stałe zatrudnienie;</View>
                                <View style={styles.listSubElement}>2.15 nieprzedłużenie lub wcześniejsze rozwiązanie umowy o pracę na czas określony.</View>
                            </View>
                        </View>
                        <View style={styles.list}>
                            <View style={styles.listHeader}>3 zakaz działań odwetowych</View>
                            <View style={styles.listSub}>
                                <View style={styles.listSubElement}>3.1	Pracodawca, wspólnie z Nami podejmuje niezbędne środki mające na celu zapewnienie ochrony przed działaniami odwetowymi zgodnie z prawem krajowym oraz prawem Unii Europejskiej.</View>
                                <View style={styles.listSubElement}>3.2	Pracodawca wspólnie z Nami wyłącza możliwość wszczęcia lub prowadzenia postępowania w przedmiocie naruszenia praw innych osób lub obowiązków określonych w przepisach prawa na gruncie postępowań, w szczególności, w przedmiocie: zniesławienia, naruszenia praw autorskich, przepisów o ochronie danych oraz obowiązku zachowania tajemnicy, w tym tajemnicy przedsiębiorstwa, a także w przedmiocie naruszenia dóbr osobistych pod warunkiem spełnienia przez Ciebie warunków do bycia objętym ochroną dla Zgłaszającego (Sygnalisty).</View>
                                <View style={styles.listSubElement}>3.3	Naruszenie praw innych osób lub obowiązków określonych w przepisach prawa może stanowić podstawę prowadzenia właściwych postępowań na ogólnych zasadach, gdy zgłoszenie lub ujawnienie informacji o naruszeniu dokonane zostanie z naruszeniem obowiązujących przepisów.</View>
                                <View style={styles.listSubElement}>3.4	Pracodawca uznaje, iż za niekorzystne traktowanie uważać się będzie w szczególności (jako najbardziej typowy przejaw możliwego niekorzystnego traktowania) rozwiązanie bądź wypowiedzenie lub odmowę nawiązanie stosunku prawnego, na podstawie którego jest lub ma być świadczona praca, bez względu na podstawę prawną świadczenia pracy.</View>
                                <View style={styles.listSubElement}>3.5	Pracodawca uznaje, iż nie jest możliwe zastosowanie przymusowego urlopu bezpłatnego w charakterze środka odwetowego z powodu ewentualnego zgłoszenia informacji o naruszeniu.</View>
                                <View style={styles.listSubElement}>3.6	Pracodawca uznaje, ż katalog kar porządkowych i przesłanek nałożenia tych kar ma charakter wyłączny - nie jest możliwe zastosowanie kary porządkowej w oparciu o inne przesłanki, w tym ewentualne zgłoszenie o naruszeniu.</View>
                                <View style={styles.listSubElement}>3.7	Pracodawca uznaje, iż jedną z form działań odwetowych (czy też, faktycznie, skutków takich działań) może być doznanie szkody, w tym nadszarpnięcie reputacji danej osoby, zwłaszcza w mediach społecznościowych, lub strat finansowych, w tym strat gospodarczych i utraty dochodu.</View>
                                <View style={styles.listSubElement}>3.8	Pracodawca razem z Nami wskazuje, iż w stosunku pracy, jeżeli Pracodawca jako druga strona stosunku prawnego dopuści się niekorzystnego traktowania z powodu zgłoszenia w inny sposób niż wypowiedzenie lub rozwiązanie bez wypowiedzenia wskazanego wyżej stosunku prawnego, będzie Ci przysługiwało prawo do odszkodowania w wysokości nie niższej niż minimalne wynagrodzenie za pracę ustalane na podstawie odrębnych przepisów.</View>
                                <View style={styles.listSubElement}>3.9	Pracodawca razem z Nami uznaje, iż działania odwetowe nie mogą być przedstawiane jako uzasadnione względami innymi niż zgłoszenie, chyba, że faktycznie istnieją ku temu uzasadnione, obiektywne i racjonalne podstawy, które Pracodawca jest wstanie wykazać.</View>
                                <View style={styles.listSubElement}>3.10 Pracodawca razem z Nami uznaje, iż obok najbardziej typowych okoliczności wyłączających bezprawność określonych w Kodeksie cywilnym, jest dokonanie zgłoszenia w przedmiocie naruszenia prawa, pod warunkiem, iż zgłoszenie zostało dokonane z zachowanie wszystkich warunków przewidzianych w przepisach prawa oraz niniejszym Regulaminie.</View>
                                <View style={styles.listSubElement}>3.11 Pracodawca uznaje razem z Nami, iż nie można zrzec się praw i środków ochrony prawnej przewidzianych dla zgłaszających naruszenia ani ich ograniczyć w drodze jakiejkolwiek umowy, polityki, formy zatrudnienia lub warunków zatrudnienia, w tym umowy o arbitraż na etapie przedspornym.</View>
                                <View style={styles.listSubElement}>3.12 Pracodawca uznaje razem z Nami, iż nie możesz przyjąć na Siebie odpowiedzialności za szkodę powstałą z powodu dokonania zgłoszenia - wszelkie takie oświadczenia lub inne czynności, jako sprzeczne z przepisami prawa powszechnie obowiązującego są nieważne.</View>
                                <View style={styles.listSubElement}>3.13 Zapewniając ochronę, o której mowa powyżej razem z Pracodawcą, w szczególności: (i) podejmujemy działania gwarantujące poszanowanie zasady poufności i anonimowości danych osobowych osoby zagłuszającej, pomagającej w zgłoszeniu, lub powiązanej, (ii) zapewniamy ochronę tożsamości na każdym etapie postępowania wyjaśniającego, jak i po jego zakończeniu, z zastrzeżeniem odrębnych postanowień Regulaminu, (iii) doprowadzamy do ukarania pracowników, którym udowodnione zostało podejmowanie jakichkolwiek działań represyjnych i odwetowych względem osoby dokonującej zgłoszenia (Sygnalisty), osoby pomagającej w dokonaniu zgłoszenia lub osoby powiązanej.</View>
                            </View>
                        </View>
                        <Text style={styles.h1}>jak przetwarzamy Twoje dane osobowe Twoje dane osobowe</Text>
                        <View style={styles.list}>
                            <View style={styles.listSub}>
                                <View style={styles.listSubElement}>1.1 Twoje dane osobowe oraz inne dane pozwalające na ustalenie Twojej tożsamości nie podlegają ujawnieniu, chyba że za Twoją wyraźną zgodą.</View>
                                <View style={styles.listSubElement}>1.2	Po otrzymaniu Twojego zgłoszenia, możemy, w celu weryfikacji Twojego zgłoszenia oraz w celu podjęcia działań następczych zbierać i przetwarzać Twoje dane osobowe osoby, nawet bez Twojej zgody.</View>
                                <View style={styles.listSubElement}>1.3	Przepisu art. 14 ust. 2 lit. f RODO (tj. obowiązku podania źródła danych osobowych) nie stosuje pod warunkiem, że miałaś/miałeś uzasadnione podstawy sądzić, że będąca przedmiotem zgłoszenia informacja o naruszeniu jest prawdziwa w momencie dokonywania zgłoszenia i, że informacja taka stanowi informację o naruszeniu prawa.</View>
                                <View style={styles.listSubElement}>1.4	Dane osobowe przetwarzane w związku z przyjęciem zgłoszenia są przechowywane przez  Nas, nie dłużej niż przez okres 5 lat od dnia przyjęcia zgłoszenia.</View>
                                <View style={styles.listSubElement}>1.5	Będziemy Cię każdorazowo informować o okolicznościach, w których ujawnienie Twojej tożsamości stanie się konieczne, np. w razie wszczęcia postępowania karnego.</View>
                            </View>
                        </View>
                        <View style={styles.list}>
                            <View style={styles.listHeader}>2 Administrator danych osobowych</View>
                            <View style={styles.listSub}>
                                <View style={styles.listSubElement}>2.1 Administratorem danych osobowych jest Twój Pracodawca oraz My, tj. Kancelaria Prawo Gospodarka Zdrowie Banaszewska Modro sp.k., a także PharmBonus spółka z o.o. w zakresie w jakim umieszczasz dane osobowe w systemie PharmBonus w moduls „Sygnalizacja”.</View>
                                <View style={styles.listSubElement}>2.2 Nie udostępniamy Twoich danych Pracodawcy, w zakresie w jakim zostały one podane w zgłoszeniu, chyba, że wyrazisz na to zgodę.</View>
                            </View>
                        </View>
                        <View style={styles.list}>
                            <View style={styles.listHeader}>3 Nasza rola w przetwarzaniu danych osobowych</View>
                            <View style={styles.listSub}>
                                <View style={styles.listSubElement}>3.1 Kancelaria Prawo Gospodarka Zdrowie Banaszewska Modro spółka komandytowa, ul. Sienkiewicza 85/87 lok.12.08, Orion House, 90-057 Łódź, wpisana do Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla m.st. Łodzi Śródmieścia w Łodzi, XX Wydział Gospodarczy Krajowego Rejestru Sądowego, pod numerem KRS 0000738686, REGON: 380634374, NIP: 7252281261, przetwarza Twoje dane osobowe w imieniu i na rzecz Pracodawcy, ale jesteśmy też Administratorem Twoich danych osobowych, w zakresie w jakim samodzielnie podejmujemy decyzję o zakresie, sposobie i celu przetwarzania Twoich danych osobowych.</View>
                                <View style={styles.listSubElement}>3.2	Z Kancelarią można się kontaktować pisemnie, za pomocą poczty tradycyjnej na adres wskazany powyżej, jak również na adres pocztowy Inspektora Ochrony Danych Osobowych m.modro@kpgz.pl .</View>
                                <View style={styles.listSubElement}>3.3	PharmBonus spółka z o.o. __________________ przetwarza Twoje dane osobowe w imieniu i na rzecz Pracodawcy, ale jesteśmy też Administratorem Twoich danych osobowych, w zakresie w jakim samodzielnie podejmujemy decyzję o zakresie, sposobie i celu przetwarzania Twoich danych osobowych.</View>
                                <View style={styles.listSubElement}>3.4	Z PharmBonus można się kontaktować pisemnie, za pomocą poczty tradycyjnej na adres wskazany powyżej, jak również na adres pocztowy Inspektora Ochrony Danych Osobowych m.modro@kpgz.pl .</View>
                            </View>
                        </View>
                        <View style={styles.list}>
                            <View style={styles.listHeader}>4 Zakres przetwarzania danych osobowych</View>
                            <View style={styles.p}>Kancelaria oraz PharmBonus przetwarzać będą  następujące kategorie Twoich danych osobowych: </View>
                            <View style={styles.listSub}>
                                <View style={styles.listSubElement}>4.1 imię;</View>
                                <View style={styles.listSubElement}>4.2	nazwisko;</View>
                                <View style={styles.listSubElement}>4.3	numer telefonu;</View>
                                <View style={styles.listSubElement}>4.4	adres poczty elektronicznej;</View>
                                <View style={styles.listSubElement}>4.5	dane dotyczące wykonywania zawodu;</View>
                                <View style={styles.listSubElement}>4.6	dane dotyczące wykonywania działalności gospodarcze; </View>
                                <View style={styles.listSubElement}>4.7	dotyczące prowadzonych postępowań; inne dane dotyczące sytuacji prawnej.</View>
                            </View>
                        </View>
                        <View style={styles.list}>
                            <View style={styles.listHeader}>5 Podstawy prawne przetwarzania danych osobowych</View>
                            <View style={styles.p}>Kancelaria oraz PharmBonus przetwarzać będą  następujące kategorie Twoich danych osobowych: </View>
                            <View style={styles.listSub}>
                                <View style={styles.listSubElement}>5.1 art. 6 ust. 1 lit. a, lit. b, lit. c, lit. d Ogólnego rozporządzenia o ochronie danych </View>
                                <View style={styles.listSubElement}>5.2 art. 9 ust. 2 lit. f Ogólnego rozporządzenia o ochronie danych;  </View>
                                <View style={styles.listSubElement}>5.3 ustawą z dnia 6 lipca 1982 r. o radcach prawnych (Dz. U. 1982 Nr 19 poz. 145 Z późn. zm.);  </View>
                                <View style={styles.listSubElement}>5.4 innymi przepisami regulującymi postępowania cywilne, karne lub administracyjne. </View>
                                <View style={styles.p}>Przetwarzanie Twoich danych osobowych jest dobrowolne i wynika również z udzielonej przez Ciebie zgody.</View>
                            </View>
                        </View>
                        <View style={styles.list}>
                            <View style={styles.listHeader}>6 Jakie prawa Ci przysługują</View>
                            <View style={styles.p}>W związku z przetwarzaniem Twoich danych osobowych, przysługują Ci następujące prawa</View>
                            <View style={styles.listSub}>
                                <View style={styles.listSubElement}>6.1	prawo dostępu do danych (art. 15  Ogólnego rozporządzenia o ochronie danych);</View>
                                <View style={styles.listSubElement}>6.2	prawo do sprostowania danych (art. 16 Ogólnego rozporządzenia o ochronie danych);</View>
                                <View style={styles.listSubElement}>6.3	prawo do usunięcia danych (art. 17 Ogólnego rozporządzenia o ochronie danych);</View>
                                <View style={styles.listSubElement}>6.4	prawo do ograniczenia przetwarzania danych (art. 18 Ogólnego rozporządzenia o ochronie danych);</View>
                                <View style={styles.listSubElement}>6.5	prawo do przenoszenia danych (art. 20 Ogólnego rozporządzenia o ochronie danych);</View>
                                <View style={styles.listSubElement}>6.6	prawo do sprzeciwu wobec zautomatyzowanego podejmowania decyzji (art. 21 Ogólnego rozporządzenia o ochronie danych).</View>
                            </View>
                            <View style={styles.p}>Jeśli zdecydujesz się skorzystać z powyższych praw, Kancelaria udzieli Ci odpowiedzi co do rozpatrzenia wniosku o realizację prawa, bez zbędnej zwłoki, jednakże nie później niż w terminie miesiąca od dnia otrzymania takiego żądania.</View>
                        </View>
                        <View style={styles.confirmCheckbox}>
                            <Checkbox
                                color='#0379F7'
                                status={confirmCheckBox ? 'checked' : 'unchecked'}
                                onPress={() => {
                                    setConfirmCheckBox(!confirmCheckBox);
                                } } />
                            <Text style={{ paddingTop: 10, fontWeight: 'bold' }}>Zapoznałem się z informacją jak przetwarzane są Moje dane osobowe
                            </Text>
                        </View>
                        <View style={{display: (!confirmCheckBox) ? 'flex' : 'none'}}>
                            <ErrorText style={{marginLeft: 35}}>{MESSAGES.confirm.checkboxError}</ErrorText>
                        </View>
                        <View style={styles.confirmCheckbox2}>
                            <Checkbox
                                color='#0379F7'
                                status={confirmCheckBox2 ? 'checked' : 'unchecked'}
                                onPress={() => {
                                    setConfirmCheckBox2(!confirmCheckBox2);
                                } } />
                            <Text style={{ paddingTop: 10, fontWeight: 'bold' }}>Zapoznałem się z informacją jakie Ma prawa
                            </Text>
                        </View>
                        <View style={{display: (!confirmCheckBox2) ? 'flex' : 'none'}}>
                            <ErrorText style={{marginLeft: 35}}>{MESSAGES.confirm.checkboxError}</ErrorText>
                        </View>
                        <View style={styles.confirmCheckbox3}>
                            <Checkbox
                                color='#0379F7'
                                status={confirmCheckBox3 ? 'checked' : 'unchecked'}
                                onPress={() => {
                                    setConfirmCheckBox3(!confirmCheckBox3);
                                } } />
                            <Text style={{ paddingTop: 10, fontWeight: 'bold' }}>Zapoznałem się z informacją jak będzie rozpoznawane Moje zgłoszenie</Text>
                        </View>
                        <View style={{display: (!confirmCheckBox3) ? 'flex' : 'none'}}>
                            <ErrorText style={{marginLeft: 35}}>{MESSAGES.confirm.checkboxError}</ErrorText>
                        </View>
                    </View>
                    <NextButton disabled={!confirmCheckBox || !confirmCheckBox2 || !confirmCheckBox3} onPress={onSubmit} />
                </>
            }
        </View>
    );
}

const styles = StyleSheet.create({
    h1: {
        fontSize: 18,
        fontWeight: 'bold'
    },
    p: {
        marginBottom: 20,
    },
    confirmCheckbox: {
        width: '100%',
        flex: 'none',
        flexDirection: 'row',
        marginLeft: 0
    },
    confirmCheckbox2: {
        width: '100%',
        flex: 'none',
        flexDirection: 'row',
        marginLeft: 0,
        marginTop: 50,
    },
    confirmCheckbox3: {
        width: '100%',
        flex: 'none',
        flexDirection: 'row',
        marginLeft: 0,
        marginTop: 50,
    },
    list: {
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
        fontSieze: 14,
        marginBottom: 10
    },
    listHeader: {
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
        fontSieze: 14,
        marginBottom: 5
    },
    listSub: {
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
        fontSieze: 14,
        marginLeft: 20,
        marginBottom: 10
    },
    listSubElement: {
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
        fontSize: 14,
        marginBottom: 3
    }
    
});