import {StyleSheet, TextInput as DefaultTextInput, TextInputProps as Props} from "react-native";
import {FieldError} from "react-hook-form";

interface TextInputProps extends Props {
    invalid?: FieldError;
}

export function TextInput(props: TextInputProps) {
    const style = StyleSheet.create({
        input: {
            height: 50,
            borderRadius: 6,
            paddingTop: 20,
            paddingBottom: 20,
            paddingLeft: 16,
            paddingRight: 16,
            borderColor: '#D1D5DB',
            borderWidth: 1,
            width: '100%',
            marginBottom: 10,
        }
    });

    return <DefaultTextInput {...props} style={[style.input, props.style]} />;
}