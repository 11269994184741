import React from 'react';
import {
    Button,
    NativeSyntheticEvent,
    NativeTouchEvent,
    ColorValue,
    ViewStyle,
    Touchable,
    TouchableOpacity,
    TextStyle
} from 'react-native';
import { commonStyles, View } from "./Themed";
import { MESSAGES } from "../constants/Messages";

interface NextButtonProps {
    title?: string;
    onPress: (ev: NativeSyntheticEvent<NativeTouchEvent>) => void;
    color?: ColorValue | undefined;
    accessibilityLabel?: string | undefined;
    disabled?: boolean | undefined;
    style?: object
}

export default function NextButton(props: NextButtonProps) {
    const data = Object.assign({}, props);

    if (!data.title)
        data.title = MESSAGES.common.nextButtonTitle;

    return (
        <View style={[commonStyles.buttonView]}>
            <TouchableOpacity {...data}>
                <View style={[commonStyles.button, commonStyles.normalButton]}>
                    <div>{data.title}</div>
                </View>
            </TouchableOpacity>
        </View>
    );
}