import React, { useState } from 'react';
import {Text, StyleSheet, TouchableHighlight, TouchableWithoutFeedback} from 'react-native';
import { commonStyles, View } from "./Themed";
import { RadioButton } from "react-native-paper";
import { Colors } from 'react-native/Libraries/NewAppScreen';

export default function RadioButtonList(props: { options: any; onCheck: any }) {
    const { options, onCheck } = props;
    const [checked, setState] = useState(options[0].value);

    function selectOption(value: string) {
        setState(value);
        onCheck(value);
    }

    return (
        <View style={{width:'100%'}}>
            {options.map((option: { title: string; text: string; value: any }) => 
            <View key={option.value} style={styles.radioButtonContainer}>
                <TouchableWithoutFeedback 
                onPress={() => {
                    selectOption(option.value)
                }}>
                    <View style={{width: '80%', margin:0, padding:0}}>
                        <Text style={commonStyles.text}>{ option.title }</Text>
                        <Text style={commonStyles.comment}>{ option.text }</Text>
                    </View>
                </TouchableWithoutFeedback>
                <View style={{width: '10%'}}>
                    <RadioButton
                        value={ option.value }
                        color= '#0379F7'
                        status={checked === option.value ? 'checked' : 'unchecked'}
                        onPress={() => { setState(option.value); }}
                    />
                </View>
            </View>)}
        </View>
    );
}

const styles = StyleSheet.create({
    radioButtonContainer: {
        flex: 1,
        flexDirection: 'row',
        borderBottomWidth: 1,
        paddingBottom: 20,
        paddingTop: 20,
        borderColor: '#F3F4F6',
        justifyContent: 'space-between',
        display:'flex',
        alignItems: 'center'
    }
});