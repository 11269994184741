import {Button, Image, StyleSheet} from 'react-native';

import {commonStyles, Text, View} from '../components/Themed';
import { RootTabScreenProps } from '../types';
import NextButton from "../components/NextButton";
import {MESSAGES} from "../constants/Messages";
import MyListButton from '../components/MyListButton';
import SignallerDataManager from '../services/SignallerDataManager';

export default function IntroduceScreen({navigation}: RootTabScreenProps<'Introduce'>) {

    const mySignallers = () => {
        navigation.navigate('MySignallers');
    }

    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    if (token)
        SignallerDataManager.getInstance()?.setToken(token);

    return (
        <View style={commonStyles.container}>
            <Image style={styles.introduce} source={require('../assets/images/introduce.svg')}/>
            <View style={commonStyles.content}>
                <Text style={commonStyles.title}>
                    { MESSAGES.introduce.title }
                </Text>
                <Text style={commonStyles.text}>
                    { MESSAGES.introduce.text }
                </Text>
            </View>
            <MyListButton
                onPress={() => {
                    navigation.navigate('MyList')
                }}
                title = {MESSAGES.introduce.mylist}
            />
            <NextButton 
                onPress={() => {
                    navigation.navigate('ChooseAuthor')
                }}
                title = {MESSAGES.introduce.button}/>
            
        </View>
    );
}

const styles = StyleSheet.create({
    introduce: {
        width: '100%',
        height: '30%',
        marginBottom: 5
    },
});
