import React from 'react';
import {
    Button,
    NativeSyntheticEvent,
    NativeTouchEvent,
    ColorValue,
    TextStyle,
    TouchableOpacity
} from 'react-native';
import { commonStyles, View } from "./Themed";
import {MESSAGES} from "../constants/Messages";

interface MyListButtonProps {
    title?: string;
    onPress: (ev: NativeSyntheticEvent<NativeTouchEvent>) => void;
    color?: ColorValue | undefined;
    accessibilityLabel?: string | undefined;
    disabled?: boolean | undefined;
}

export default function MyListButton(props: MyListButtonProps) {
    const data = Object.assign({}, props);

    if (!data.title)
        data.title = MESSAGES.common.myListTitle;

    return (
        <View style={[commonStyles.buttonMyListView]}>
             <TouchableOpacity {...data}>
                <View style={[commonStyles.button, commonStyles.lightButton, {marginTop:10}]}>
                    <div>{data.title}</div>
                </View>
            </TouchableOpacity>
        </View>
    );
}