/**
 * Learn more about Light and Dark modes:
 * https://docs.expo.io/guides/color-schemes/
 */

import {StyleSheet, Text as DefaultText, View as DefaultView} from 'react-native';

import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';

const myListStyles = StyleSheet.create({
    listTitle: {
        textAlign: 'left',
        fontSize: 16,
        fontWeight: '400',
        lineHeight: 20,
        marginBottom: 10,
    },
    listSubTitle: {
        textAlign: 'left',
        fontSize: 16,
        fontWeight: '500',
        lineHeight: 24,
        marginBottom: 5,
    },
    listContentText: {
        fontSize: 16,
        fontWeight: '400',
        lineHeight: 24,
    },
    colorNeutralDark: {
        color: '#111827'
    },
    colorNeutralDark2: {
        color: '#4B5563'
    },
    listDate: {
        textAlign: 'left',
        fontSize: 14,
        fontWeight: '400',
        lineHeight: 20,
        marginBottom: 10,
    },
    listIcon: {
        position: 'absolute',
        right: 10,
        top: '50%',
        marginTop: '-11px'
    },
    button: {
        width: '100%',
        borderBottomColor: '#F3F4F6',
        borderBottomWidth: 1,
        paddingBottom: 10,
        paddingTop: 10
    },
    listContent: {
        width: '100%',
        marginBottom: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#F3F4F6',
        paddingBottom: 10
    },
    listColumns: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: 5,
        paddingBottom: 15
    }
});

const commonStyles = StyleSheet.create({
    defaultFont: {
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
    },
    container: {
        flex: 1,
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%'
    },
    content: {
        padding: 20,
        width: '100%'
    },
    title: {
        fontSize: 22,
        fontWeight: '500',
        lineHeight: 28,
        marginBottom: 10,
        width: '100%'
    },
    text: {
        fontSize: 16,
        lineHeight: 24,
    },
    comment: {
        fontSize: 14,
        color: '#4B5563',
        lineHeight: 20,
    },
    button: {
        borderRadius: Colors.borderRadius,
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
        fontSize: 14,
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 48
    },
    normalButton: {
        backgroundColor: Colors.blueColor,
        color: '#ffffff'
    },
    lightButton: {
        backgroundColor: Colors.lightblueColor,
        color: Colors.blueColor
    },
    buttonView: {
        position: 'absolute',
        bottom: 15,
        width: '100%',
        paddingLeft: 20,
        paddingRight: 20,
        height: 48,
    },
    buttonMyListView: {
        position: 'absolute',
        bottom: 85,
        width: '100%',
        paddingLeft: 20,
        paddingRight: 20,
        height: 48,
        fontWeight: '500',
    },
    topImage: {
        width: '100%',
        height: '30%',
        marginBottom: 5
    },
    form: {
        width: '100%',
        marginTop: 10
    }
});

export {commonStyles, myListStyles}

export function useThemeColor(
    props: { light?: string; dark?: string },
    colorName: keyof typeof Colors.light & keyof typeof Colors.dark
) {
    const theme = useColorScheme();
    const colorFromProps = props[theme];

    if (colorFromProps) {
        return colorFromProps;
    } else {
        return Colors[theme][colorName];
    }
}

type ThemeProps = {
    lightColor?: string;
    darkColor?: string;
};

export type TextProps = ThemeProps & DefaultText['props'];
export type ViewProps = ThemeProps & DefaultView['props'];

export function Text(props: TextProps) {
    const {style, lightColor, darkColor, ...otherProps} = props;
    const color = useThemeColor({light: lightColor, dark: darkColor}, 'text');

    return <DefaultText style={[{color}, style]} {...otherProps} />;
}

export function View(props: ViewProps) {
    const {style, lightColor, darkColor, ...otherProps} = props;
    const backgroundColor = useThemeColor({light: lightColor, dark: darkColor}, 'background');

    return <DefaultView style={[{backgroundColor}, style]} {...otherProps} />;
}
