import { commonStyles, Text, View } from '../components/Themed';
import { RootTabScreenProps } from "../types";
import RadioButtonList from "../components/RadioButtonList";
import NextButton from "../components/NextButton";
import SignallerDataManager from "../services/SignallerDataManager";
import { FormTypesEnum } from "../constants/FormTypesEnum";
import {MESSAGES} from "../constants/Messages";

export default function FormTypeScreen({navigation}: RootTabScreenProps<'FormType'>) {
    const options = [
        {
            title: MESSAGES.formType.open,
            text: MESSAGES.formType.openDescription,
            value: FormTypesEnum.open
        },
        {
            title: MESSAGES.formType.anonymous,
            text: MESSAGES.formType.anonymousDescription,
            value: FormTypesEnum.close
        },
    ];

    function loadFormType()
    {
        if (SignallerDataManager.getInstance()?.isAnonymousForm()) {
            navigation.navigate('OrganizationInfo');
        } else {
            navigation.navigate('PersonalInfo');
        }
    }

    function selected(value: any)
    {
        SignallerDataManager.getInstance()?.setFormType(value);
    }

    selected(options[0].value);

    return (
        <View style={[commonStyles.container, commonStyles.content]}>
            <Text style={commonStyles.title}>{ MESSAGES.formType.title }</Text>

            <RadioButtonList options={options} onCheck={selected} />

            <NextButton onPress={loadFormType} />
        </View>
    );
}
