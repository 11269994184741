import { commonStyles, Text, View } from '../components/Themed';
import { RootTabScreenProps } from "../types";
import React from "react";
import NextButton from "../components/NextButton";
import { Controller, useForm } from "react-hook-form";
import { ErrorText } from "../components/ErrorText";
import { TextInput } from "../components/TextInput";
import SignallerDataManager from "../services/SignallerDataManager";
import {MESSAGES} from "../constants/Messages";

export default function PersonalInfoScreen({navigation}: RootTabScreenProps<'PersonalInfo'>) {
    const { control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '+48'
        }
    });

    const onSubmit = (data: any) => {
        SignallerDataManager.getInstance()?.setPersonalInfo(data);
        navigation.navigate('OrganizationInfo');
    };

    return (
        <View style={[commonStyles.container, commonStyles.content]}>
            <Text style={commonStyles.title}>{ MESSAGES.personalInfo.title }</Text>
            <View style={commonStyles.form}>
                <Controller
                    control={control}
                    rules={{
                        required: true,
                        maxLength: 64,
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <TextInput
                            placeholder={ MESSAGES.personalInfo.namePlaceholder }
                            onBlur={onBlur}
                            onChangeText={onChange}
                            value={value}
                        />
                    )}
                    name="firstName"
                />

                {errors.firstName && <ErrorText>{ MESSAGES.common.requiredErrorFirstname }</ErrorText>}

                <Controller
                    control={control}
                    rules={{
                        required: true,
                        maxLength: 64,
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <TextInput
                            placeholder={ MESSAGES.personalInfo.lastNamePlaceholder }
                            onBlur={onBlur}
                            onChangeText={onChange}
                            value={value}
                        />
                    )}
                    name="lastName"
                />

                {errors.lastName && <ErrorText>{ MESSAGES.common.requiredErrorLastname }</ErrorText>}

                <Controller
                    control={control}
                    rules={{
                        required: true,
                        maxLength: 64,
                        pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <TextInput
                            placeholder={ MESSAGES.common.emailPlaceholder }
                            onBlur={onBlur}
                            onChangeText={onChange}
                            value={value}
                        />
                    )}
                    name="email"
                />

                {errors.email && <ErrorText>{ MESSAGES.common.emailError }</ErrorText>}

                <Controller
                    control={control}
                    rules={{
                        required: true,
                        maxLength: 16,
                        minLength: 8
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <TextInput
                            placeholder={ MESSAGES.personalInfo.phonePlaceholder }
                            onBlur={onBlur}
                            onChangeText={onChange}
                            value={value}
                        />
                    )}
                    name="phone"
                />

                {errors.phone && <ErrorText>{ MESSAGES.common.requiredErrorPhone }</ErrorText>}
            </View>

            <NextButton onPress={handleSubmit(onSubmit)} />
        </View>
    );
}

