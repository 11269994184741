const tintColorLight = '#2f95dc';
const tintColorDark = '#fff';
const blueColor = '#0379F7';
const lightGray = '#F3F4F6';
const lightblueColor = '#E6F2FF';
const borderRadius = 6;

export default {
  light: {
    text: '#000',
    background: '#fff',
    tint: tintColorLight,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorLight,
  },
  dark: {
    text: '#000',
    background: '#fff',
    tint: tintColorDark,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorDark,
  },
  lightGray,
  blueColor,
  lightblueColor,
  borderRadius
};
