import { commonStyles, myListStyles, Text, View } from '../components/Themed';
import { RootTabScreenProps } from "../types";
import { FlatList, SafeAreaView, TouchableOpacity } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import React, { useEffect, useState } from 'react';
import SignallerDataManager from '../services/SignallerDataManager';
import Globals from '../constants/Globals';
import { ActivityIndicator } from 'react-native-paper';
import { Item } from 'react-native-paper/lib/typescript/components/List/List';
import { MESSAGES } from '../constants/Messages';


export default function MyListScreen({navigation}: RootTabScreenProps<'MyList'>) {
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    const token = SignallerDataManager.getInstance()?.getToken();

    const getListSignalist = async () => {
        try {
            const response = await fetch(Globals.API_URL+'/v2.5/signaller/'+token);
            const json = await response.json();
            setData(json);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    function getDate (date:any) {
        return new Date(date*1000).toLocaleDateString("pl-PL");
    }

    useEffect(() => {
        getListSignalist();
      }, []);

    return (
        <View style={[commonStyles.container, commonStyles.content, {justifyContent:'center'}]}>
            {isLoading ? <ActivityIndicator/> : data? (
                <View style={{justifyContent: "center", width: "100%", marginVertical: 10, flex: 1,}}>
                    <FlatList
                        contentContainerStyle={{width: '100%'}}
                        data={data}
                        numColumns = {1}
                        keyExtractor={({ id }, index) => id}
                        renderItem={({ item } : {item:any}) => (
                            <TouchableOpacity onPress={()=>{navigation.navigate('MyListContent',{id:item.id})}} hitSlop={{top: 20, bottom: 20, left: 50, right: 50}} style={[myListStyles.button]}>
                                <View>
                                    <Text style={[myListStyles.listTitle]}>
                                        {item.title}
                                    </Text>
                                    <Text style={[myListStyles.listDate]}>
                                        {MESSAGES.mylistContent.sent} {getDate(item.created_at)}
                                    </Text>
                                </View>
                                <Ionicons style={[myListStyles.listIcon]} name="ios-arrow-forward" size={20} color="black" />
                            </TouchableOpacity>
                        )}
                    />
                </View>
                ):(
                <View>
                    <Text>
                        {MESSAGES.mylistContent.nodata}
                    </Text>
                </View>
            )}
        </View>
    );
}