import { commonStyles, Text, View } from '../components/Themed';
import { RootTabScreenProps } from "../types";
import React, {useState} from "react";
import NextButton from "../components/NextButton";
import SignallerDataManager from "../services/SignallerDataManager";
import {TextInput} from "../components/TextInput";
import { Controller, useForm } from "react-hook-form";
import { ErrorText } from "../components/ErrorText";
import { StyleSheet } from "react-native";
import {Picker} from "@react-native-picker/picker";
import { MESSAGES } from "../constants/Messages";

export default function OrganizationInfoScreen({navigation}: RootTabScreenProps<'OrganizationInfo'>) {
    const [selectedReason, setSelectedValue] = useState('');
    
    const reasons = MESSAGES.organizationInfo.reasons;

    const { control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            title: '',
            position: '',
            reason: '',
        }
    });

    const onSubmit = (data: any) => {
        SignallerDataManager.getInstance()?.setOrganizationInfo({
            title: data.title,
            position: data.position,
            reason: selectedReason
        });
        navigation.navigate('ViolationDetails');
    };

    function renderPosition () {
        if (!SignallerDataManager.getInstance()?.isAnonymousForm())
            return (<View>
                    <Controller
                        control={control}
                        rules={{
                            required: true,
                            maxLength: 64,
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <TextInput
                                placeholder={ MESSAGES.organizationInfo.positionPlaceholder }
                                onBlur={onBlur}
                                onChangeText={onChange}
                                value={value}
                            />
                        )}
                        name="position"
                        />

                    {errors.position && <ErrorText>{MESSAGES.organizationInfo.errorPosition}</ErrorText>}
                </View>
            );
    }

    return (
        <View style={[commonStyles.container, commonStyles.content]}>
            <Text style={commonStyles.title}>{ MESSAGES.organizationInfo.title }</Text>
            <View style={commonStyles.form}>
                <Controller
                    control={control}
                    rules={{
                        required: true,
                        maxLength: 64,
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <TextInput
                            placeholder={ MESSAGES.organizationInfo.organizationNamePlaceholder }
                            onBlur={onBlur}
                            onChangeText={onChange}
                            value={value}
                        />
                    )}
                    name="title"
                />

                {errors.title && <ErrorText>{MESSAGES.organizationInfo.errorName}</ErrorText>}

                { renderPosition() }

                <Text style={{ marginBottom: 15, marginTop: 5 }}>Zgłoszenia dotyczy naruszenia prawa w obszarze (możesz zaznaczyć więcej niż jeden):</Text>

                <Picker
                    selectedValue={selectedReason}
                    style={styles.picker}
                    onValueChange={(itemValue) => setSelectedValue(itemValue)}
                >
                    { reasons.map((reason, index) => (
                        <Picker.Item key={index} label={reason} value={index} />
                    )) }
                </Picker>
            </View>

            <NextButton onPress={handleSubmit(onSubmit)} />
        </View>
    );
}

const styles = StyleSheet.create({
    picker: {
        height: 50,
        borderRadius: 6,
        borderColor: '#D1D5DB',
        borderWidth: 1,
        width: '100%',
        paddingLeft: 10
    }
});