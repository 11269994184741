import { FormTypesEnum } from "../constants/FormTypesEnum";

export default class SignallerDataManager {

    static myInstance: SignallerDataManager | null = null;

    private data = {
        token: null,
        authorType: null,
        formType: null,
        description: null,
        personalInfo: {},
        organizationInfo: {},
        affectedPersons: [],
        responsiblePersons: [],
        witnessesPersons: [],
        evidencesPersons: [],
        reason: 0,
        files:[]
    };

    /**
     * @returns {SignallerDataManager}
     */
    static getInstance() {
        if (SignallerDataManager.myInstance == null) {
            SignallerDataManager.myInstance = new SignallerDataManager();
        }

        return this.myInstance;
    }

    public setToken(token: any)
    {
        this.data.token = token;
    }

    public getToken() {
        return this.data.token;
    }

    public getAuthorType() {
        return this.data.authorType;
    }

    public setAuthorType(authorType: any) {
        this.data.authorType = authorType;
    }

    public getFormType() {
        return this.data.formType;
    }

    public isAnonymousForm() {
        return this.getFormType() === FormTypesEnum.close;
    }

    public setFormType(authorType: any) {
        this.data.formType = authorType;
    }

    public getData() {
        return this.data;
    }

    public setPersonalInfo(info: any) {
        this.data.personalInfo = info;
    }

    public setOrganizationInfo(info: any) {
        this.data.organizationInfo = info;
    }

    public setAffectedPersons(persons: any) {
        this.data.affectedPersons = persons;
    }

    public setResponsiblePersons(persons: any) {
        this.data.responsiblePersons = persons;
    }

    public setWitnessesPersons(persons: any) {
        this.data.witnessesPersons = persons;
    }

    public setEvidencesPersons(persons: any) {
        this.data.evidencesPersons = persons;
    }

    public setReason(reason: number) {
        this.data.reason = reason;
    }

    public setDescription(description: any) {
        this.data.description = description;
    }
    public setFiles(files: any) {
        this.data.files = files;
    }
}