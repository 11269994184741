import { commonStyles, Text, View } from '../components/Themed';
import { RootTabScreenProps } from "../types";
import NextButton from "../components/NextButton";
import SignallerDataManager from "../services/SignallerDataManager";
import React from "react";
import TextInputDynamicList from "../components/TextInputDynamicList";
import {MESSAGES} from "../constants/Messages";

export default function AffectedPersonsScreen({navigation}: RootTabScreenProps<'AffectedPersons'>) {
    let affected: any;
    let responsible: any;

    const affectedChanged = (data: any) => {
        affected = data;
    }

    const responsibleChanged = (data: any) => {
        responsible = data;
    }

    const submit = () => {
        SignallerDataManager.getInstance()?.setAffectedPersons(affected);
        SignallerDataManager.getInstance()?.setResponsiblePersons(responsible);

        navigation.navigate('WitnessesPersons');
    };

    return (
        <View style={[commonStyles.container, commonStyles.content]}>
            <Text style={commonStyles.title}>{ MESSAGES.affectedPersons.title }</Text>
            <Text style={commonStyles.text}>{ MESSAGES.affectedPersons.text }</Text>

            <TextInputDynamicList onChange={affectedChanged} placeholder = {MESSAGES.affectedPersons.inputPersonName} title={ MESSAGES.affectedPersons.affected } addLabel={ MESSAGES.affectedPersons.addAffected } />
            <TextInputDynamicList onChange={responsibleChanged} placeholder = {MESSAGES.affectedPersons.inputPersonName} title={ MESSAGES.affectedPersons.responsible } addLabel={ MESSAGES.affectedPersons.addResponsible } />

            <NextButton onPress={submit} />
        </View>
    );
}
