/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */

import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';
import {Button, ColorSchemeName} from 'react-native';

import NotFoundScreen from '../screens/NotFoundScreen';
import IntroduceScreen from '../screens/IntroduceScreen';
import ChooseAuthorScreen from '../screens/ChooseAuthorScreen';
import MyListScreen from '../screens/MyListScreen';
import MyListContentScreen from '../screens/MyListContentScreen';
import { RootStackParamList } from '../types';
import LinkingConfiguration from './LinkingConfiguration';
import FormTypeScreen from "../screens/FormTypeScreen";
import OrganizationInfoScreen from "../screens/OrganizationInfoScreen";
import PersonalInfoScreen from "../screens/PersonalInfoScreen";
import ViolationDetailsScreen from "../screens/ViolationDetailsScreen";
import AffectedPersonsScreen from "../screens/AffectedPersonsScreen";
import WitnessesPersonsScreen from "../screens/WitnessesPersonsScreen";
import ConfirmScreen from "../screens/ConfirmScreen";
import SuccessScreen from "../screens/SuccessScreen";
import HeaderBackButton from "@react-navigation/elements/src/Header/HeaderBackButton";
import Bridge from "../services/Bridge";
import MySignallersScreen from "../screens/MySignallersScreen";
import { MESSAGES } from '../constants/Messages';

export default function Navigation({ colorScheme }: { colorScheme: ColorSchemeName }) {
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}>
      <RootNavigator />
    </NavigationContainer>
  );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {
  return (
    <Stack.Navigator>
      <Stack.Screen name="Introduce" component={IntroduceScreen} options={({navigation, route}) => ({
          title: 'Sygnalizacja',
          headerLeft: (props) => (
              <HeaderBackButton
                  {...props}
                  onPress={() => Bridge.exit()}
              />
          ),
      })}
      />
      <Stack.Screen name="ChooseAuthor" options={{ title: 'Sygnalizacja' }} component={ChooseAuthorScreen} />
      <Stack.Screen name="FormType" options={{ title: 'Sygnalizacja' }} component={FormTypeScreen} />
      <Stack.Screen name="PersonalInfo" options={{ title: 'Sygnalizacja' }} component={PersonalInfoScreen} />
      <Stack.Screen name="OrganizationInfo" options={{ title: 'Sygnalizacja' }} component={OrganizationInfoScreen} />
      <Stack.Screen name="ViolationDetails" options={{ title: 'Sygnalizacja' }} component={ViolationDetailsScreen} />
      <Stack.Screen name="AffectedPersons" options={{ title: 'Sygnalizacja' }} component={AffectedPersonsScreen} />
      <Stack.Screen name="WitnessesPersons" options={{ title: 'Sygnalizacja' }} component={WitnessesPersonsScreen} />
      <Stack.Screen name="Confirm" options={{ title: 'Sygnalizacja' }} component={ConfirmScreen} />
      <Stack.Screen name="Success" options={{ title: 'Sygnalizacja' }} component={SuccessScreen} />
      <Stack.Screen name="MySignallers" options={{ title: 'Сообщить о нарушении' }} component={MySignallersScreen} />
      <Stack.Screen name="MyList" options={{ title: MESSAGES.mylist.title }} component={MyListScreen} />
      <Stack.Screen name="MyListContent" options={{ title: MESSAGES.mylist.title }} component={MyListContentScreen} />
      <Stack.Screen name="NotFound" component={NotFoundScreen} options={{ title: 'Oops!' }} />
    </Stack.Navigator>
  );
}