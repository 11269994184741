import GLOBALS from '../constants/Globals';
import {Button, Image, StyleSheet, TouchableWithoutFeedback} from 'react-native';
import {commonStyles, Text, View} from '../components/Themed';
import { RootTabScreenProps } from '../types';
import NextButton from "../components/NextButton";
import {MESSAGES} from "../constants/Messages";
import {RadioButton} from "react-native-paper";
import React from "react";
import axios from "axios";
import SignallerDataManager from "../services/SignallerDataManager";

export default function MySignallersScreen({navigation}: RootTabScreenProps<'MySignallers'>) {
    let signallers: { title: string; text: string; id: any; }[] = [];

    axios.get(GLOBALS.API_URL+'/v2.5/signaller', {
        params: {
            token: SignallerDataManager.getInstance()?.getToken()
        }
    })
        .then(() => {
            navigation.navigate('Success');
        });

    const select = (id: any) => {
        console.log(id);
    };

    return (
        <View style={[commonStyles.container, commonStyles.content]}>
            <Text>Мои обращения</Text>
            {signallers.map((option: { title: string; text: string; id: any }) => <View key={option.id} style={styles.radioButtonContainer}>
                <TouchableWithoutFeedback onPress={() => {
                    select(option.id)
                }}>
                    <View style={{width: '80%'}}>
                        <Text style={commonStyles.text}>{ option.title }</Text>
                        <Text style={commonStyles.comment}>{ option.text }</Text>
                    </View>
                </TouchableWithoutFeedback>
                <View style={{width: '10%'}}>
                    <Text>go</Text>
                </View>
            </View>)}
        </View>
    );
}

const styles = StyleSheet.create({
    radioButtonContainer: {
        flex: 1,
        flexDirection: 'row',
        borderBottomWidth: 1,
        paddingBottom: 25,
        paddingTop: 20,
        borderColor: '#F3F4F6',
        justifyContent: 'space-between'
    }
});